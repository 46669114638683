import React, { useEffect, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomNavbar from "../components/Navbar";
import { Row, Col, Badge } from "react-bootstrap";

function Briefs() {
  const [briefItems, setBriefItems] = useState([]);
  const [filter, setFilter] = useState("All Briefs");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchBriefs = fetch("../api/briefs/")
      .then((response) => response.json())
      .then((json) => setBriefItems(json))
      .catch((error) => console.error("Error fetching briefs:", error));

    const fetchUsers = fetch("../api/users/")
      .then((response) => response.json())
      .then((json) => setUsers(json))
      .catch((error) => console.error("Error fetching users:", error));

    Promise.all([fetchBriefs, fetchUsers])
      .then(() => console.log("All fetches completed"))
      .catch((error) => console.error("Error completing fetches:", error));
  }, []);

  const handleFilterChange = (status) => {
    setFilter(status);
  };

  // Filtered briefs based on the selected filter
  const filteredBriefItems = briefItems.filter((item) => {
    if (filter === "All Briefs") return true;
    if (filter === "Complete") return item.status === "Completed";
    if (filter === "In Progress") return item.status === "In Progress";
    if (filter === "Not Started") return item.status === "Not Started";
    return true;
  });

  return (
    <>
      <CustomNavbar />
      <Container>
        <div className="header d-flex justify-content-between">
          <div>
            <h1>ALL BRIEFS</h1>
          </div>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                className="line-btn"
                variant="outline-secondary"
                id="dropdown-basic"
              >
                {filter}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleFilterChange("All Briefs")}>
                  All Briefs
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleFilterChange("Complete")}>
                  Complete
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleFilterChange("In Progress")}
                >
                  In Progress
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleFilterChange("Not Started")}
                >
                  Not Started
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="brief-list">
          {filteredBriefItems.map((item, index) => {
            const owner = users.find((user) => user.id === item.owner);
            const commenters = item.comments.map((commentId) =>
              users.find((user) => user.id === commentId)
            );

            return (
              <div className="brief-item" key={index}>
                <Link to={`/briefs/${index}`}>
                  <div className="brief-content">
                    <Row>
                      <Col className="d-flex align-items-center">
                        <Col className="d-flex space-between">
                          {owner && (
                            <img
                              src={owner.avatar}
                              alt={owner.name}
                              className="avatar creator"
                            />
                          )}
                          <p className="client-name two-line">
                            {item.clientName}
                          </p>
                        </Col>
                        <Badge className={item.status.toLowerCase()}>
                          {item.status}
                        </Badge>
                      </Col>
                    </Row>
                    <p className="brief-meta">
                      <span className="brief-office">
                        <i className="fa-regular fa-building"></i> {item.office}
                      </span>
                      <span className="brief-date">
                        <i className="fa-regular fa-calendar"></i>{" "}
                        {new Date(item.timestamp * 1000).toDateString()}
                      </span>
                      <span className="brief-location">
                        <i className="fa-regular fa-map-marker-alt"></i>{" "}
                        {item.location} ({item.region})
                      </span>
                    </p>
                    <h2 className="brief-title one-line">{item.title}</h2>
                    <p className="brief-description one-line">
                      {item.description}
                    </p>
                  </div>
                  <div className="brief-stats">
                    <span className="brief-views">
                      <i className="fa-regular fa-eye"></i>{" "}
                      <span className="bold">{item.views}</span> views
                    </span>
                    <span className="brief-comments">
                      <i class="fa-regular fa-comment"></i>{" "}
                      <span className="bold">{item.comments.length}</span>{" "}
                      comments
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </Container>
    </>
  );
}

export default Briefs;
