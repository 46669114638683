import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Brief from "./pages/Brief";
import Briefs from "./pages/Briefs";
import Dashboard from "./pages/Dashboard";
import Ideas from "./pages/Ideas";
import Login from "./pages/Login";
import "./styles/main.scss";

export default function App() {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/ideas" element={<Ideas />} />
                <Route path="/briefs/" element={<Briefs />} />
                <Route path="/briefs/:id" element={<Brief />} />
            </Routes>
        </Router>
    );
}
