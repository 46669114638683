import React from "react";
import { Modal } from "react-bootstrap";
import CommentForm from "./CommentForm";

function CommentModal({ currentUser, show, onHide, onPostComment }) {
    return (
        <Modal className="modal-xl" show={show} onHide={onHide} centered dialogClassName="custom-comment-modal">
            <Modal.Body>
                <CommentForm currentUser={currentUser} onPostComment={onPostComment} />
            </Modal.Body>
        </Modal>
    );
}

export default CommentModal;
