import React, { useState } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import BriefModal from "./BriefModal";
import { Link, useLocation } from "react-router-dom";

export default function CustomNavbar() {
    const [addBrief, setAddBrief] = useState(false);
    const location = useLocation();

    return (
        <>
            <Navbar expand="lg" className="mb-4 position-fixed w-100">
                <Container fluid className="d-flex justify-content-between">
                    <Navbar.Brand as={Link} to="/">
                        <Button variant="link" className="p-0">
                            <img src={require("../assets/logos/unbox_logo.png")} alt="Logo" width="130" />
                        </Button>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav id="links-nav" className="me-auto d-flex align-items-center">
                            <Nav.Link className={`me-3 ${location.pathname === "/briefs" ? "active" : ""}`} as={Link} to="/briefs">
                                BRIEFS
                            </Nav.Link>
                            <Nav.Link className={`me-3 ${location.pathname === "/ideas" ? "active" : ""}`} as={Link} to="/ideas">
                                IDEATION
                            </Nav.Link>
                            <Nav.Link className={`me-3 ${location.pathname === "/people" ? "active" : ""}`} href="#">
                                PEOPLE
                            </Nav.Link>
                        </Nav>
                        <Nav id="search-nav">
                            <Button variant="dark" className="black-btn i" onClick={() => setAddBrief(true)}>
                                <i className="far fa-layer-plus"></i>
                            </Button>
                            <Button variant="dark" className="black-btn search-btn">
                                SEARCH
                            </Button>
                            <Button variant="dark" className="black-btn i">
                                <i className="far fa-user"></i>
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <BriefModal show={addBrief} onHide={() => setAddBrief(false)} />
        </>
    );
}
