import React from "react";
import { timeAgo } from "../utils/timeUtils";
import { Button, Col, Row } from "react-bootstrap";

function BriefOwnerPost({ owner, brief, onCommentClick }) {
    if (!owner) return null;

    return (
        <Row>
            <Col xs={11} className="comment">
                <div className="comment-content">
                    <div className="comment-user">
                        <div className="comment-avatar">
                            <img src={owner.avatar} alt={owner.name} className="avatar" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                        </div>
                        <div className="comment-title">
                            <h5>
                                {owner.name} <br />
                                <span className="text-muted small">{owner.role}</span> <br />
                                <small className="text-muted">{timeAgo(brief.timestamp)}</small>
                            </h5>
                        </div>
                    </div>
                    <div className="comment-message mt-2">
                        <p>{brief.description}</p>
                    </div>
                </div>

                <Row>
                    <Col className="idea-actions">
                        <Button variant="dark" className="black-btn" onClick={onCommentClick}>
                            <i class="fa-regular fa-bolt"></i> <span>add idea</span>
                        </Button>
                    </Col>
                    <Col className="post-actions">
                        <Button variant="dark" className="post-action ">
                            <i class="fa-regular fa-eye"></i> <span>{brief.views}</span>
                        </Button>
                        <Button variant="dark" className="post-action ">
                            <i class="fa-regular fa-heart"></i> <span>30</span>
                        </Button>
                        <Button variant="dark" className="post-action " onClick={onCommentClick}>
                            <i class="fa-regular fa-comment"></i> <span>{brief.comments.length}</span>
                        </Button>

                        <Button variant="dark" className="post-action ">
                            <i class="fa-regular fa-bell"></i>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default BriefOwnerPost;
