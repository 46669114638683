import { Col, Container, Row } from "react-bootstrap";
import { BotMessage, UserMessage } from "../components/IdeaMessage";
import CustomNavbar from "../components/Navbar";

export default function Ideas() {
  return (
    <div id="ideas" className="m-5">
      <CustomNavbar />
      <Container>
        <Row className="m-0">
          <Col className="col-9 bg-grey1 d-flex align-items-center">
            <h1>IDEATION</h1>
          </Col>
          <Col className="ms-3 bg-grey1">
            <button className="bg-none d-flex align-items-center justify-content-between w-100 h-100">
              <h1>BRIEFS</h1>
              <i className="fa-solid fa-caret-down"></i>
            </button>
          </Col>
        </Row>

        <div id="ideas-chatbox" className="my-4">
          <BotMessage />
          <UserMessage />
          <BotMessage />
          <UserMessage />
          <BotMessage />
          <UserMessage />
          <BotMessage />
          <UserMessage />
          <BotMessage />
          <UserMessage />
        </div>
      </Container>

      <div
        id="ideas-input"
        className="d-flex justify-content-between p-3 position-fixed w-100"
      >
        <Container className="bg-grey1">
          <input
            type="text"
            placeholder="START TYPING HERE TO IDEATE..."
            className="w-100"
          />
          <button className="outline-btn">POST</button>
        </Container>
      </div>
    </div>
  );
}
