import React from "react";
import { Button } from "react-bootstrap";

export default function Login() {
    const handleGoogleSignIn = () => {
        // Add your Google sign-in logic here
        alert("Google Sign-In Clicked");
    };

    return (
        <div className="login-container">
            <img src={require("../assets/logos/unbox_logo.png")} alt="Unbox Logo" className="login-logo" />
            <Button className="google-signin-button" onClick={handleGoogleSignIn}>
                <i class="fa-brands fa-google"></i> Sign in with Google
            </Button>
        </div>
    );
}
