import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

function BriefModal(props) {
  const [fileInputs, setFileInputs] = useState([{ id: 1, value: "" }]);
  const [step, setStep] = useState(1);

  const handleAddFileInput = () => {
    setFileInputs([...fileInputs, { id: fileInputs.length + 1, value: "" }]);
  };

  const handleFileInputChange = (id, value) => {
    setFileInputs(
      fileInputs.map((input) => (input.id === id ? { ...input, value } : input))
    );
  };

  const handleRemoveFileInput = (id) => {
    if (fileInputs.length === 1) return;
    setFileInputs(fileInputs.filter((input) => input.id !== id));
  };

  const renderStepOne = () => (
    <>
      <Form.Group controlId="briefTitle">
        <Form.Label>TITLE</Form.Label>
        <Form.Control type="text" placeholder="Enter title" />
      </Form.Group>
      <Form.Group controlId="briefDescription" className="mt-3">
        <Form.Label>DESCRIPTION</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Enter description" />
      </Form.Group>
      <Form.Group controlId="briefFiles" className="mt-3">
        <Form.Label>FILES</Form.Label>
        {fileInputs.map((input) => (
          <div key={input.id} className="d-flex align-items-center mb-2">
            <Form.Control
              type="text"
              value={input.value}
              onChange={(e) => handleFileInputChange(input.id, e.target.value)}
              placeholder="Enter file URL"
              className="me-2"
            />
            <Button
              className="file-remove-btn"
              onClick={() => handleRemoveFileInput(input.id)}
            >
              <i className="fa fa-x"></i>
            </Button>
          </div>
        ))}
        <Button onClick={handleAddFileInput} className="mt-2 line-btn">
          ADD FILE
        </Button>
      </Form.Group>
    </>
  );

  const renderStepTwo = () => (
    <>
      <h4 className="mb-2">OPTIONAL INFORMATION</h4>
      <Row>
        <Col>
          <Form.Group controlId="briefClient">
            <Form.Label>CLIENT</Form.Label>
            <Form.Control type="text" placeholder="Enter client" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="briefIndustry">
            <Form.Label>INDUSTRY</Form.Label>
            <Form.Control type="text" placeholder="Enter industry" />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form.Group controlId="briefOwner">
            <Form.Label>OWNER</Form.Label>
            <Form.Control type="text" placeholder="Enter owner" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="briefCollaborate">
            <Form.Label>COLLABORATE</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter collaboration details"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form.Group controlId="briefProjectType">
            <Form.Label>PROJECT TYPE</Form.Label>
            <Form.Control type="text" placeholder="Enter project type" />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="briefLocation">
            <Form.Label>LOCATION</Form.Label>
            <Form.Control type="text" placeholder="Enter location" />
          </Form.Group>
        </Col>
      </Row>
    </>
  );

  return (
    <Modal {...props} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>BRIEF SUBMISSION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {step === 1 && renderStepOne()}
          {step === 2 && renderStepTwo()}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {step > 1 && (
          <Button
            className="black-btn"
            variant="secondary"
            onClick={() => setStep(step - 1)}
          >
            Previous
          </Button>
        )}
        {step < 2 ? (
          <Button
            className="black-btn"
            variant="primary"
            onClick={() => setStep(step + 1)}
          >
            Next
          </Button>
        ) : (
          <Button
            className="black-btn"
            variant="primary"
            onClick={props.onHide}
          >
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default BriefModal;
