import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BriefOwnerPost from "../components/BriefOwnerPost";
import CommentSection from "../components/BriefCommentSection";
import BriefInfo from "../components/BriefInfo";
import CustomNavbar from "../components/Navbar";
import CommentModal from "../components/CommentModal";

function Brief() {
  const { id } = useParams();
  const [brief, setBrief] = useState(null);
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [showCommentModal, setShowCommentModal] = useState(false);

  useEffect(() => {
    fetch("../api/briefs/")
      .then((response) => response.json())
      .then((data) => {
        setBrief(data[id]);
      })
      .catch((error) => console.error("Error fetching brief data:", error));

    fetch("../api/comments/")
      .then((response) => response.json())
      .then((data) => {
        const filteredComments = data.filter(
          (comment) => comment.briefId === (parseInt(id) + 1).toString()
        );
        setComments(filteredComments);
      })
      .catch((error) => console.error("Error fetching comments:", error));

    fetch("../api/users/")
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
        setCurrentUser(data[0]);
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, [id]);

  const handlePostComment = (commentText) => {
    if (currentUser) {
      const newComment = {
        userId: currentUser.id,
        briefId: (parseInt(id) + 1).toString(),
        content: commentText,
        timestamp: Math.floor(Date.now() / 1000),
      };
      setComments([...comments, newComment]);
    }
    setShowCommentModal(false);
  };

  if (!brief || !currentUser) {
    return <div>Loading...</div>;
  }

  const owner = users.find((user) => user.id === brief.owner);

  return (
    <div className="m-5">
      <CustomNavbar />
      <Container fluid>
        <div id="brief">
          <Row>
            <Col lg={3}>
              <BriefInfo brief={brief} />
            </Col>
            <Col lg={6}>
              <BriefOwnerPost
                owner={owner}
                brief={brief}
                onCommentClick={() => setShowCommentModal(true)}
              />

              <CommentModal
                currentUser={currentUser}
                show={showCommentModal}
                onHide={() => setShowCommentModal(false)}
                onPostComment={handlePostComment}
              />

              <div id="comment-section">
                <p className="my-2">
                  Comments{" "}
                  <span className="small">({brief.comments.length})</span>
                </p>
                {comments.map((comment, index) => {
                  const user = users.find((user) => user.id === comment.userId);
                  return (
                    <CommentSection
                      key={index}
                      comment={{
                        ...comment,
                        userName: user ? user.name : "Unknown User",
                        userRole: user ? user.role : "Unknown Title",
                        userAvatar: user
                          ? user.avatar
                          : "../assets/avatar/default.png",
                      }}
                    />
                  );
                })}
              </div>
            </Col>
            <Col lg={3}>
              <h4 className="mt-3">Saved Ideas</h4>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Brief;
