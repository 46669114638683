import { Col, Row } from "react-bootstrap";

export const BotMessage = () => {
  return (
    <div className="bot-message">
      <Row>
        <Col className="col-1 me-3">
          <img
            width="40px"
            src="https://placeholder.co/400x400"
            alt="placeholder"
          />
        </Col>
        <Col>
          <h2>BOT NAME</h2>
          <p className="my-1">Some sort of message...</p>
          <div>
            <button>LIKE</button>
            <button>DISLIKE</button>
            <button>COPY</button>
            <button>SAVE</button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const UserMessage = () => {
  return (
    <div className="user-message px-4 py-3">
      <p>Some sort of message...</p>
    </div>
  );
};
