/**
 * Converts a UNIX timestamp to a relative time string.
 * @param {number} unixTimestamp - The UNIX timestamp to convert.
 * @returns {string} - The relative time string (e.g., "2 hours ago").
 */
export function timeAgo(unixTimestamp) {
  const now = new Date();
  const secondsPast = Math.floor(now.getTime() / 1000 - unixTimestamp);

  if (secondsPast < 60) {
    return `${secondsPast} seconds ago`;
  } else if (secondsPast < 3600) {
    // less than an hour
    const minutes = Math.floor(secondsPast / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (secondsPast < 86400) {
    // less than a day
    const hours = Math.floor(secondsPast / 3600);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (secondsPast < 2592000) {
    // less than a month (30 days)
    const days = Math.floor(secondsPast / 86400);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (secondsPast < 31536000) {
    // less than a year (12 months)
    const months = Math.floor(secondsPast / 2592000);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else {
    const years = Math.floor(secondsPast / 31536000);
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }
}
