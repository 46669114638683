import React from "react";
import { Col } from "react-bootstrap";

function BriefInfo({ brief }) {
  return (
    <Col xl={10} className="briefInfo">
      <p className="client-name two-line">{brief.clientName}</p>
      <div className="brief-title">
        <h1>{brief.title}</h1>
      </div>
      <div className="brief-desc">
        <p>{brief.description}</p>
      </div>
      <div className="d-flex justify-content-between">
        <button className="line-btn w-50">Read</button>
      </div>
    </Col>
  );
}

export default BriefInfo;
