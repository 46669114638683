import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";

function CommentForm({ currentUser, onPostComment }) {
    const [commentText, setCommentText] = useState("");

    const handlePostComment = () => {
        if (commentText.trim()) {
            onPostComment(commentText);
            setCommentText(""); // Clear the input after posting
        }
    };

    return (
        <Col className="comment auth-user">
            <div className="comment-content d-flex align-items-center">
                <div className="comment-avatar">
                    <img src={currentUser.avatar} alt={currentUser.name} className="avatar" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                </div>
                <div className="comment-input flex-grow-1 mx-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Write an idea and stick to one idea per comment..."
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                    />
                </div>
                <div className="comment-post">
                    <Button variant="dark" className="black-btn" onClick={handlePostComment}>
                        <i class="fa-regular fa-paper-plane-top"></i>
                    </Button>
                </div>
            </div>
        </Col>
    );
}

export default CommentForm;
