import React from "react";
import { timeAgo } from "../utils/timeUtils";
import { Row, Col } from "react-bootstrap";

function CommentSection({ comment }) {
    return (
        <Row>
            <Col xs={10} className="comment">
                <div className="comment-content">
                    <div className="comment-user">
                        <div className="comment-avatar">
                            <img src={comment.userAvatar} alt={comment.userName} className="avatar" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                        </div>
                        <div className="comment-title">
                            <h5>
                                {comment.userName} <br /> <span className="text-muted small">{comment.userRole}</span> <br />
                                <small className="text-muted">{timeAgo(comment.timestamp)}</small>
                            </h5>
                        </div>
                    </div>
                    <div className="comment-message">
                        <p className="mb-1">{comment.content}</p>
                    </div>
                </div>
            </Col>
            <Col xs={1}>
                <div className="comment-actions">
                    <span className="comment-action">
                        <i class="fa-regular fa-heart"></i>
                    </span>
                    <span className="comment-action">
                        <i class="fa-regular fa-comment"></i>
                    </span>
                    <span className="comment-action">
                        <i class="fa-regular fa-bookmark"></i>
                    </span>
                </div>
            </Col>
        </Row>
    );
}

export default CommentSection;
