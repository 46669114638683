import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import CustomNavbar from "../components/Navbar";
import { timeAgo } from "../utils/timeUtils";
import { Row, Col } from "react-bootstrap";

export default function Dashboard() {
  const [briefItems, setBriefItems] = useState([]);
  const [ideaItems, setIdeaItems] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchBriefs = fetch("../api/briefs/")
      .then((response) => response.json())
      .then((json) => setBriefItems(json))
      .catch((error) => console.error("Error fetching briefs:", error));

    const fetchIdeas = fetch("../api/ideas/")
      .then((response) => response.json())
      .then((json) => setIdeaItems(json))
      .catch((error) => console.error("Error fetching ideas:", error));

    const fetchUsers = fetch("../api/users/")
      .then((response) => response.json())
      .then((json) => setUsers(json))
      .catch((error) => console.error("Error fetching users:", error));

    Promise.all([fetchBriefs, fetchIdeas, fetchUsers])
      .then(() => console.log("All fetches completed"))
      .catch((error) => console.error("Error completing fetches:", error));
  }, []);

  const buttons = [
    { label: "FILTER", onClick: null },
    { label: "SEARCH", onClick: null },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
    },
    LargeDesktop: {
      breakpoint: { max: 1224, min: 768 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <div id="dashboard" className="m-5">
      <CustomNavbar />
      <Container fluid>
        <Header heading="ACTIVE BRIEFS" buttons={buttons} />
        <Carousel responsive={responsive} arrows={true} showDots>
          {briefItems.map((item, index) => {
            const owner = users.find((user) => user.id === item.owner.id);
            const commenters = item.comments.map((commentId) =>
              users.find((user) => user.id === commentId)
            );
            return (
              <div className="carousel-item-content" key={index}>
                <Link to={`/briefs/${index}`}>
                  <div className="briefCard">
                    <Row>
                      <Col xs={7}>
                        <p className="office-info">
                          <i className="fa-regular fa-building"></i>{" "}
                          {item.office}
                        </p>
                        <p className="timestamp">
                          <i className="fa-regular fa-clock"></i>{" "}
                          {timeAgo(item.timestamp)}
                        </p>
                      </Col>
                      <Col xs={5} className="client-logo">
                        <p className="client-name two-line">
                          {item.clientName}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <p className="location one-line">
                        <i className="fa-regular fa-map-marker-alt"></i>{" "}
                        {item.location} ({item.region})
                      </p>
                      <h2 className="two-line mt-3">{item.title}</h2>
                      <p className="three-line">{item.description}</p>
                    </Row>
                    <Row className="mt-3">
                      <Col className="one-line">
                        {owner && (
                          <img
                            src={owner.avatar}
                            alt={owner.name}
                            className="avatar creator"
                          />
                        )}

                        {commenters.map(
                          (commenter, idx) =>
                            commenter && (
                              <img
                                key={idx}
                                src={commenter.avatar}
                                alt={commenter.name}
                                className="avatar commenters"
                              />
                            )
                        )}
                        <br />
                      </Col>
                    </Row>
                  </div>
                </Link>
              </div>
            );
          })}
        </Carousel>

        <Header heading="TRENDING IDEAS" buttons={buttons} />
        <Carousel responsive={responsive} arrows={false} showDots>
          {ideaItems.map((item, index) => (
            <div className="carousel-item-content" key={index}>
              <Link to={`/briefs`}>
                <img src={item.image} alt={`Slide ${index + 1}`} />
                <h1>{item.header}</h1>
                <p>{item.subheader}</p>
              </Link>
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  );
}
