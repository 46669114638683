import PropTypes from "prop-types";
import React from "react";

const Header = ({ heading, buttons = [] }) => {
  return (
    <div className="header d-flex justify-content-between">
      <div>
        <h1>{heading}</h1>
      </div>
      <div>
        {buttons.map((button, index) => (
          <button key={index} className="line-btn" onClick={button.onClick}>
            {button.label}
          </button>
        ))}
      </div>
    </div>
  );
};

Header.propTypes = {
  heading: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
};

export default Header;
